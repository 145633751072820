import React, { useEffect, useRef } from "react";
import "./InputField.css";

function InputField({
  value,
  onChange,
  type,
  placeholder,
  error,
  disabled,
  language,
  name,
  toggleLanguage,
  symbolLimit,
  onFocusOut,
  className,
}) {
  const myOnChange = (e) => {
    if (e.target.value.length > symbolLimit) {
      return;
    }
    onChange(e);
  };

  useEffect(() => {
    return () => {
      onChange({
        target: { value: "" },
      });
    };
  }, []);

  return (
    <div className="input-field">
      {value && <label>{placeholder}</label>}
      <input
        onWheel={null}
        type={type}
        className={`input ${error && "input-error"} 
          ${className ? className : ""}`}
        placeholder={placeholder}
        value={value}
        onChange={myOnChange}
        disabled={disabled}
        onBlur={onFocusOut ? onFocusOut : () => {}}
        name={name}
      />
      {language && (
        <div
          className="language-switcher"
          onClick={toggleLanguage}
          style={{ top: 15 }}
        >
          {language}
        </div>
      )}
      {symbolLimit && (
        <div className="input-symbol-limit">
          {value.length} / {symbolLimit}
        </div>
      )}
    </div>
  );
}

export default InputField;
